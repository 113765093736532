<template>
  <div>
    <div>
      <b-row
        align-h="end"
        class="mb-2"
      >
        <b-col cols="auto">
          <b-button
            variant="primary"
            @click="$refs.convenioModal.show(), edit = true"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            Convenio
          </b-button>
        </b-col>
      </b-row>
      <b-modal
        ref="convenioModal"
        hide-footer
        size="lg"
        @hide="infoModal = {}"
      >
        <convenio-modal
          :info-convenio="infoModal"
          :close-modal="closeModal"
          :edit="edit"
        />
      </b-modal>
    </div>
    <div>
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-card>
          <b-row
            align-h="center"
            class="mb-2"
          >
            <b-col md="8">
              <b-form-input
                v-model="filter"
                placeholder="Pesquisar"
                type="search"
                size="sm"
              />
            </b-col>
          </b-row>
          <b-table
            striped
            responsive
            sticky-header="65vh"
            :filter="filter"
            :fields="fields"
            :items="items"
          >
            <template #cell(opcoes)="data">
              <b-row>
                <b-col cols="auto">
                  <feather-icon
                    icon="EditIcon"
                    class="color-icon"
                    @click="openModal(data.item)"
                  />
                </b-col>
                <b-col cols="auto">
                  <feather-icon
                    icon="Trash2Icon"
                    class="trash-icon"
                    @click="deleteConvenio(data.item)"
                  />
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BCard,
  BTable,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'
import ConvenioModal from './ConvenioModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    ConvenioModal,
    BCard,
    BTable,
    BFormInput,
    BOverlay,
  },
  data() {
    return {
      fields: [
        {
          key: 'codigo_banco', label: 'cod',
        },
        {
          key: 'nome_banco', label: 'banco',
        },
        {
          key: 'nome_beneficiario', label: 'beneficiario',
        },
        {
          key: 'parametro.dias_compensacao', label: 'compensacao', class: 'text-right',
        },
        {
          key: 'convenio', class: 'text-right',
        },
        {
          key: 'carteira', class: 'text-right',
        },
        {
          key: 'agencia', class: 'text-right',
        },
        {
          key: 'conta', class: 'text-right',
        },
        'opcoes',
      ],
      items: [],
      filter: '',
      currentPage: '',
      totalRows: '',
      infoModal: {},
      loas: false,
      edit: true,
    }
  },
  created() {
    this.getConvenios()
  },

  methods: {
    openModal(item) {
      this.infoModal = item
      this.edit = false
      this.$refs.convenioModal.show()
    },
    async closeModal() {
      await this.$refs.convenioModal.hide()
      await this.getConvenios()
    },
    getConvenios() {
      this.load = true
      this.items = []
      axios.get('api/v1/parametro_boleto', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.items.unshift({
            nome_beneficiario: dt.nome_beneficiario,
            cnpj_beneficiario: dt.cnpj_beneficiario,
            endereco_beneficiario: dt.endereco_beneficiario,
            id: dt.id,
            codigo_banco: dt.codigo_banco,
            nome_banco: dt.nome_banco,
            convenio: dt.convenio,
            carteira: dt.carteira,
            agencia: dt.agencia,
            conta: dt.conta,
            parametro: JSON.parse(dt.parametro),
            credor_id: dt.credor_id,
          })
        })
        this.totalRows = this.items.length
        this.load = false
      })
    },
    deleteConvenio(item) {
      this.$swal({
        title: 'Tem certeza?',
        text: 'Deseja deletar o convenio?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (await result.value) {
          await axios.delete(`api/v1/parametro_boleto/delete/${item.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }).then(async () => {
            await this.getConvenios()
            this.$swal({
              icon: 'success',
              title: 'Deletado!',
              text: 'Convenio deletado com sucesso!',
              timer: 1500,
              showConfirmButton: false,
            })
          })
            .catch(() => {
              this.$swal({
                title: 'ERRO',
                icon: 'error',
                text: 'Ocorreu um erro durante a operação!',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
  },
}
</script>
