<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-row>
          <b-col
            cols="12"
          >
            <b-card title="Dados Beneficiário:">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  Nome:
                  <b-form-input
                    v-model="con.nome_beneficiario"
                  />
                </b-col>
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  CNPJ:
                  <cleave
                    v-model="con.cnpj_beneficiario"
                    class="form-control"
                    :options="mask.cnpj"
                  />
                </b-col>
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  Endereço:
                  <b-form-input
                    v-model="con.endereco_beneficiario"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="Dados Bancarios:">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  Nome do Banco:
                  <v-select
                    v-model="banco"
                    :options="bancos"
                  />
                </b-col>
                <b-col
                  cols="6"
                  class="mb-1"
                >
                  Convênio:
                  <b-form-input
                    v-model="con.convenio"
                  />
                </b-col>
                <b-col
                  cols="6"
                  class="mb-1"
                >
                  Carteira:
                  <b-form-input
                    v-model="con.carteira"
                  />
                </b-col>
                <b-col
                  cols="4"
                  class="mb-1"
                >
                  Agência:
                  <b-form-input
                    v-model="con.agencia"
                  />
                </b-col>
                <b-col
                  cols="8"
                  class="mb-1"
                >
                  Conta:
                  <b-form-input
                    v-model="con.conta"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col
            cols="12"
          >
            <!-- Parametros: -->
            <b-card title="Parâmetros:">
              <div
                v-if="banco.parametros"
              >
                <b-row
                  v-for="item in Object.keys(banco.parametros)"
                  :key="item"
                >
                  <b-col
                    cols="12"
                    class="mb-1"
                  >
                    {{ item.replace('_', ' ') }}:
                    <b-form-input
                      v-model="banco.parametros[item]"
                    />
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col
                  v-if="edit"
                  cols="12"
                  class="mb-1"
                >
                  dias compensação:
                  <b-form-input
                    v-model="compensacaoNome.dias_compensacao"
                    type="number"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-col
        v-if="edit"
        cols="auto"
      >
        <b-button
          variant="primary"
          @click="storeConvenio"
        >
          Salvar
        </b-button>
      </b-col>
      <b-col
        v-if="!edit"
        cols="auto"
      >
        <b-button
          variant="primary"
          @click="updateConvenio"
        >
          Atualizar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  BCard,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BCard,
    BButton,
    vSelect,
    Cleave,
  },
  props: {
    infoConvenio: {
      type: Object,
      required: false,
      default() {
        return null
      },
    },
    edit: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      bancos: [],
      banco: {
        value: '',
        label: '',
        parametros: {},
      },
      compensacaoNome: {
        dias_compensacao: '',
      },
      con: {
        nome_beneficiario: '',
        cnpj_beneficiario: '',
        endereco_beneficiario: '',
        carteira: '',
        agencia: '',
        conta: '',
        convenio: '',
      },

      mask: {
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
      },
    }
  },
  async created() {
    await this.getBancos()
    await this.setItems()
  },
  methods: {
    setItems() {
      this.banco = {
        value: this.infoConvenio.codigo_banco,
        label: this.infoConvenio.nome_banco,
        parametros: this.infoConvenio.parametro,
      }
      this.con = {
        nome_beneficiario: this.infoConvenio.nome_beneficiario,
        cnpj_beneficiario: this.infoConvenio.cnpj_beneficiario,
        endereco_beneficiario: this.infoConvenio.endereco_beneficiario,
        carteira: this.infoConvenio.carteira,
        razao_social: this.infoConvenio.razao_social,
        agencia: this.infoConvenio.agencia,
        conta: this.infoConvenio.conta,
        convenio: this.infoConvenio.convenio,
      }
    },

    getBancos() {
      axios.get('https://grupovoz.net.br/bancos').then(res => {
        res.data.map(dt => {
          this.bancos.push({
            label: dt.nome,
            value: dt.codigo,
            parametros: dt.parametroBoleto,
          })
        })
      })
    },

    async updateConvenio() {
      const body = {
        nome_beneficiario: this.con.nome_beneficiario,
        cnpj_beneficiario: this.con.cnpj_beneficiario.replace(/[^0-9]/g, ''),
        endereco_beneficiario: this.con.endereco_beneficiario,
        codigo_banco: parseInt(this.banco.value),
        nome_banco: this.banco.label,
        convenio: parseInt(this.con.convenio),
        carteira: this.con.carteira,
        agencia: (this.con.agencia),
        conta: (this.con.conta),
        parametro: JSON.stringify(this.banco.parametros),
      }

      await axios.put(`api/v1/parametro_boleto/update/${this.infoConvenio.id}`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(async () => {
        await this.closeModal()
        await this.$swal({
          title: 'Conveino atualizado!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
      })
        .catch(async () => {
          await this.closeModal()
          await this.$swal({
            title: 'ERRO',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            buttonsStyling: false,
          })
        })
    },

    async storeConvenio() {
      const body = {
        nome_beneficiario: this.con.nome_beneficiario,
        cnpj_beneficiario: this.con.cnpj_beneficiario.replace(/[^0-9]/g, ''),
        endereco_beneficiario: this.con.endereco_beneficiario,
        codigo_banco: parseInt(this.banco.value),
        nome_banco: this.banco.label,
        convenio: parseInt(this.con.convenio),
        carteira: this.con.carteira,
        agencia: (this.con.agencia),
        conta: (this.con.conta),
        parametro: JSON.stringify({ ...this.banco.parametros, ...this.compensacaoNome }),
      }
      await axios.post('api/v1/parametro_boleto/store', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(async () => {
        this.$swal({
          title: 'Conveino adicionado!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
        await this.closeModal()
      })
        .catch(async () => {
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            buttonsStyling: false,
          })
          await this.closeModal()
        })
    },

  },
}
</script>
